@import '~styles/_form';
@import '~styles/_colors';

.input {
  @include form-item-base;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: rgba(blue, 0.5);
    box-shadow: 0 0 0 1px rgba(blue, 0.2);
  }

  &_error {
    border-color: orangered;
  }
}

.error {
  color: orangered;
}
