.root {
  position: fixed;
  display: inline-flex;
  width: 100%;
  z-index: 9999;
  bottom: 0px;
  left: 0px;
  min-height: 40px;
  background-color: tomato;
  padding: 12px;
  white-space: nowrap;
}
