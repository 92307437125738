@import '~styles/_responsive';
@import '~styles/_colors';

.root {
  > div {
    background-color: $color-background-canvas;
  }
}

.close {
  position: absolute;
  right: 16px;
  top: 24px;
}

.content {
  padding: 24px !important;
  min-width: 360px;

  @include match-medium {
    min-width: 480px;
  }
}
