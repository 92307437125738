@import "~styles/_responsive";

.root {
  width: 100%;
  
  @include match-large {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;

  @include match-large {
    flex-direction: row;
  }
}

.actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  > :first-child {
    margin-right: 10px;
  }
}
