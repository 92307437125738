@import '~styles/utils';

.root {
  width: 100%;
}

.rowLink {
  text-decoration: none !important;
  min-height: 48px;
}

.entityColumn {
  max-width: 160px;

  @include overflow-ellipsis;
}

