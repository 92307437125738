.root {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin-top: 15px;

  &:first-of-type {
    margin-top: 0px;
  }
}
