@import '~styles/_animations';
@import '~styles/_colors';
@import '~styles/_fonts';

@mixin form-item-base() {
  @include font-display-1;

  display: block;
  width: 100%;
  padding: 5px 10px;
  line-height: 25px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background: #fff;
  outline: none;
  -webkit-appearance: auto;
  transition-duration: $transition-duration-slow;
  transition-property: box-shadow, border-color;

  &:focus {
    outline: none;
    @include form-item-focus;
  }
}

@mixin reset-button {
  border: none;
  background-color: transparent;

  &:not([disabled]) {
    cursor: pointer;
  }
}

@mixin form-item-focus() {
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(98, 152, 233, 0.25);

  transition-duration: $transition-duration-fast;
}
