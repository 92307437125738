@import '~styles/utils';
@import '~styles/animations';
@import '~styles/colors';

.root {
  width: 100%;
}

.rowLink {
  text-decoration: none !important;
  min-height: 48px;

  @include animate-fast();

  &_selected {
    background-color: $blue-light;
  }
}

.gameperiodColumn {
  width: 100px;
  max-width: 160px;

  @include overflow-ellipsis;
}

.economicaccColumn {
  width: 100px;
  max-width: 160px;

  @include overflow-ellipsis;
}

.landscapeColumn {
  width: 40px;
  max-width: 40px;
}