@import '~styles/_form';
@import '~styles/_colors';

.input {
  @include form-item-base;
  font-size: 16px;

  &_error {
    border-color: orangered;
  }

  &_readOnly {
    opacity: 0.7;
    cursor: default;

    &:focus {
      box-shadow: none;
    }
  }
}

.error {
  color: orangered;
}
