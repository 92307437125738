.root {
  width: 100%;
}

.table {
  margin-top: 32px;
}

.protocols {
  margin-top: 16px !important;
}