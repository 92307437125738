.root {
  margin-top: 30px;
}

.form {
  display: flex;
  flex-direction: column;
}

.submit {
  margin-top: 10px;
}

.spinner {
  margin-right: 10px;
}

.middlename {
  max-width: 100px;
}

.a {
  color: black;
  text-decoration: none;
  white-space: nowrap;
}
