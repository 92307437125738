@import '~styles/_form';

.root {
  @include reset-button;
  display: inline-flex;
  margin-top: 15px;
  padding: 9px 15px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  background-color: royalblue;
  border-radius: 3px;
  color: white;
  font-weight: 700;
  align-self: flex-end;
  text-transform: uppercase;
  font-size: 14px;

  &_disabled {
    opacity: 0.6;
  }
}
