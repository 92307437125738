@import '~styles/_form';
@import '~styles/_colors';

.input {
  @include form-item-base;
  font-size: 16px;

  &_error {
    border-color: orangered;
  }
}

.error {
  color: orangered;
}
