@import '../../styles/_colors';

.root {
  width: 100%;
  height: 100%;
}

.list {
  width: 100%;

  &:not(:first-of-type) {
    padding-top: 16px !important;
  }

}

.gameSelectWrapper {
  margin-top: 0px;
  padding-left: 12px !important;
  color: $black;
}

.gameSelect {
  padding: 8px !important;
}

.login {
  text-transform: none !important;
}

@mixin link-background {
  background-color: rgba($black, 0.1) !important;
}

.logout {
  text-transform: none !important;
  color: $black;

  &:active,
  &:visited {
    color: $black;
  }

  &:hover {
    @include link-background;
  }
}