@import '~styles/colors';

.delete {
  color: $color-warning !important;
  &_disabled {
    opacity: 0.5 !important;
  }
}

.error {
  color: $color-warning !important;
}

.actions {
  position: relative;
}

.progress {
  position: absolute;
  top: 50%;
  right: 64px;
  margin-top: -12px;
  margin-left: -12px;
}
