@import '../../styles/_colors';

@mixin link-background {
  background-color: rgba($white, 0.1) !important;
}

.item {
  color: $white;

  &:active,
  &:visited {
    color: $white;
  }

  &:hover {
    @include link-background;
  }

  &_active {
    > * * {
      font-weight: 700 !important;
      transition: background-color 250ms ease-in;
    }
  }
}
