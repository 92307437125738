$font-stack-serif: 'Roboto', sans-serif;

@mixin font-display-1 {
  font-family: $font-stack-serif;
  font-size: 15px;
  line-height: 20px;
}

@mixin font-caption {
  font-family: $font-stack-serif;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  -webkit-font-smoothing: antialiased;
}
