@import '~styles/colors';

.root {
  margin-top: 24px;
  max-width: 100%;
}

.toolbar {
  display: flex;
}

.filter {
  font-weight: 700 !important;
  margin-right: 24px !important;

  &:not(:first-of-type) {
    margin-left: 24px;
  }
}
