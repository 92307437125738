@import '~styles/utils';

.root {
  width: 100%;
}

.table {
  width: 100%;
}

.tableContainer {
  overflow-x: auto;
  max-height: 72vh;
}

.noMoreResults {
  display: flex;
  margin-top: 24px;
  width: 100%;
  justify-content: center;
}

.rowLink {
  text-decoration: none !important;
  min-height: 48px;
}

.detailRow {
  min-height: 48px;
  font-size: small;
  justify-content: right;
}

.gameperiodColumn {
  max-width: 160px;

  @include overflow-ellipsis;
}

.entityColumn {
  max-width: 160px;

  @include overflow-ellipsis;
}

.roleColumn {
  max-width: 260px;

  @include overflow-ellipsis;
}
