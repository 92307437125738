$transition-duration-fast: 0.1s;
$transition-duration-slow: 0.3s;
$transition-duration-slower: 0.4s;
$transition-duration-slowest: 0.8s;

$transition-curve-move: cubic-bezier(0.4, 0, 0.2, 1);
$transition-curve-move-sharp: cubic-bezier(0.4, 0, 0.6, 1);

@mixin animate-move($attr: transform) {
  transition: $attr $transition-duration-slow $transition-curve-move;
}

@mixin animate-move-sharp($attr: transform) {
  transition: $attr $transition-duration-slow $transition-curve-move-sharp;
}

@mixin animate-slow($attr: all) {
  transition: $attr $transition-duration-slow linear;
}

@mixin animate-fast($attr: all) {
  transition: $attr $transition-duration-fast linear;
}

@mixin hover-fast-in-slow-out($attr: all) {
  @include animate-slow($attr);

  &:hover {
    @include animate-fast($attr);
  }
}

@mixin hover-slow-in-slow-out($attr: all) {
  @include animate-slow($attr);
}
