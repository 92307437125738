.table {
  width: 100%;
}

.tableContainer {
  overflow-x: auto;
  max-height: 65vh;
  width: 100%;
}

.noEntries {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
}
