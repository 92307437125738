.root {
  margin-top: 8px;
}

.form {
  display: flex;
  flex-direction: column;
}

.submit {
  margin-top: 10px;
}

.spinner {
  margin-right: 10px;
}
