@mixin fullbleed {
  position: fixed;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.25);
}

@mixin after {
  &::after {
    content: '';
    display: block;
    @content;
  }
}

@mixin overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  // Safari workaround
  @include after();
}
