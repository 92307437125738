.root {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.loadingMessage {
  margin-top: 8px !important;
}