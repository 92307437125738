.root {
  width: 100%;
}

.table {
  margin-top: 32px;
}

.newEntity {
  margin-top: 16px !important;
  margin-right: 5px !important;
}