@import '~styles/utils';
@import '~styles/animations';
@import '~styles/colors';

.root {
  width: 100%;
}

.table {
  width: 100%;
}

.tableContainer {
  overflow-x: auto;
  max-height: 72vh;
}

.noMoreResults {
  display: flex;
  margin-top: 24px;
  width: 100%;
  justify-content: center;
}

.rowLink {
  text-decoration: none !important;
  min-height: 48px;

  @include animate-fast();

  &_selected {
    background-color: $blue-light;
  }
}

.gameperiodColumn {
  max-width: 120px;

  @include overflow-ellipsis;
}

.entityColumn {
  max-width: 160px;

  @include overflow-ellipsis;
}

.roleColumn {
  max-width: 260px;

  @include overflow-ellipsis;
}
