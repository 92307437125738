@mixin match-medium {
  @media (min-width: 720px) {
    @content;
  }
}

@mixin match-large {
  @media (min-width: 1080px) {
    @content;
  }
}

@mixin container {
  $padding: 10px;

  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $padding;
  padding-right: $padding;

  @include match-medium {
    $padding: 15px;

    padding-left: $padding;
    padding-right: $padding;
  }
}
