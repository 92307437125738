.root {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin-top: 15px;

  &:first-of-type {
    margin-top: 0px;
  }
}

.error,
.label {
  font-weight: 500 !important;
}

.error {
  color: orangered;
}

.input {
  width: 99%;
  margin-top: 2px;
}
